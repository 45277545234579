<template>
<div class="content">
  <vxe-grid ref="xGrid" v-bind="gridOptions" :data="tableData"></vxe-grid>
</div>
</template>

<script>
export default {
  data() {
    return {
      totalAmount: 0,
      tableData: [],
      gridOptions: {
        // 列表字段配置
        // 列表功能按钮组
        toolbarConfig: {
          enabled: false,
        },
        columns: [{
            field: "feeName",
            minWidth: 150,
            title: "<span class=normal>项目名称</span>",
            type: "html",
          },
          {
            field: "feePeriod",
            minWidth: 150,
            title: "<span class=normal>费用期间</span>",
            type: "html",
          },
          {
            field: "coefficient",
            title: "<span class=normal>系数</span>",
            type: "html",
            minWidth: 50,
            editRender: {
              name: "$input",
            }
          },
          {
            field: "quantity",
            title: "<span class=normal>实用量</span>",
            type: "html",
            minWidth: 70,
            editRender: {
              name: "$input",
            }
          },
          {
            field: "unitPrice",
            title: "<span class=normal>单价</span>",
            type: "html",
            minWidth: 40,
            editRender: {
              name: "$input",
            }
          },
          {
            field: "receivableAmount",
            title: "<span class=normal>应收金额</span>",
            type: "html",
            minWidth: 80,
            formatter: ({ cellValue }) => {
              if (!cellValue) {
                return "0.000"
              } else if (cellValue != "-") {
                return this.$XEUtils.commafy(Number(cellValue), { digits: 3 })
              } else {
                return cellValue
              }
            },
            editRender: {
              name: "$input",
              props: {
                type: "float",
                min: 0,
                max: 99999,
                maxlength: 24,
                placeholder: "请输入应收金额"
              }
            }
          },
          {
            field: "amountReceived",
            title: "<span class=normal>已收金额</span>",
            type: "html",
            minWidth: 80,
            formatter: ({ cellValue }) => {
              if (!cellValue) {
                return "0.000"
              } else if (cellValue != "-") {
                return this.$XEUtils.commafy(Number(cellValue), { digits: 3 })
              } else {
                return cellValue
              }
            },
            editRender: {
              name: "$input",
              props: {
                type: "float",
                min: 0,
                max: 99999,
                maxlength: 24,
                placeholder: "请输入已收金额"
              }
            }
          },
          {
            field: "lateFee",
            title: "<span class=normal>违约金</span>",
            type: "html",
            minWidth: 70,
            formatter: ({ cellValue }) => {
              if (!cellValue) {
                return "0.000"
              } else if (cellValue != "-") {
                return this.$XEUtils.commafy(Number(cellValue), { digits: 3 })
              } else {
                return cellValue
              }
            },
            editRender: {
              name: "$input",
              props: {
                type: "float",
                min: 0,
                max: 99999,
                maxlength: 24,
                placeholder: "请输入违约金"
              }
            }
          },
          {
            field: "predepositOffset",
            title: "<span class=normal>预存冲抵</span>",
            type: "html",
            minWidth: 80,
            formatter: ({ cellValue }) => {
              if (!cellValue) {
                return "0.000"
              } else if (cellValue != "-") {
                return this.$XEUtils.commafy(Number(cellValue), { digits: 3 })
              } else {
                return cellValue
              }
            },
            editRender: {
              name: "$input",
              props: {
                type: "float",
                min: 0,
                max: 99999,
                maxlength: 24,
                placeholder: "请输入预存冲抵"
              }
            }
          },
          {
            field: "receivedAmount",
            title: "<span class=normal>本次交款</span>",
            type: "html",
            minWidth: 80,
            formatter: ({ cellValue }) => {
              if (!cellValue) {
                return "0.000"
              } else if (cellValue != "-") {
                return this.$XEUtils.commafy(Number(cellValue), { digits: 3 })
              } else {
                return cellValue
              }
            },
            editRender: {
              name: "$input",
              props: {
                type: "float",
                min: 0,
                max: 99999,
                maxlength: 24,
                placeholder: "请输入实收金额"
              }
            }
          },
          {
            field: "arrears",
            title: "<span class=normal>欠款</span>",
            type: "html",
            minWidth: 70,
            formatter: ({ cellValue }) => {
              if (!cellValue) {
                return "0.000"
              } else if (cellValue != "-") {
                return this.$XEUtils.commafy(Number(cellValue), { digits: 3 })
              } else {
                return cellValue
              }
            },
            editRender: {
              name: "$input",
              props: {
                type: "float",
                min: 0,
                max: 99999,
                maxlength: 24,
                placeholder: "请输入实收金额"
              }
            }
          },
        ],
        editConfig: {
          enabled: false
        },
        printConfig: {
          isMerge: true,
        },
        // showFooter: true,
        footerMethod: ({ columns, data }) => {
          return [
            columns.map((column, _columnIndex) => {
              if (_columnIndex === 0) {
                return '合计'
              }
              if (_columnIndex === 1) {
                return '0.00'
              }
              if (['amountReceived', 'lateFee', 'predepositOffset', 'arrears'].includes(column.property)) {
                return this.sumNum(data, column.property)
              }
              return null
            }),
            columns.map((column, _columnIndex) => {
              if (_columnIndex === 0) {
                return '摘要'
              }
              return null
            })
          ]
        },
      },
    };
  },

  methods: {
    printEvent(data) {
      let mergeRow = 0;
      data.detailPrintDTOList.map((item, index) => {
        if (item.feeType == "999") {
          mergeRow = index;
          item["coefficient"] = "-";
          item["receivableAmount"] = "-";
          item["amountReceived"] = "-";
          item["lateFee"] = "-";
          item["predepositOffset"] = "-";
          item["receivedAmount"] = Number(item["receivedAmount"]);
          item["arrears"] = "-";
        } else {
          data["amountReceived"] += Number(item["amountReceived"] || 0);
          data["lateFee"] += Number(item["lateFee"]);
          data["predepositOffset"] += Number(item["predepositOffset"]);
          data["arrears"] += Number(item["arrears"]);
        }
      });
      this.tableData = data.detailPrintDTOList;
      this.gridOptions.mergeCells = [
        { row: mergeRow, col: 2, rowspan: 1, colspan: 3 },
      ];
      this.totalAmount = data["totalAmount"];
      data["receivableAmount"] = this.$XEUtils.commafy(data["receivableAmount"], { digits: 3 });
      data["amountReceived"] = this.$XEUtils.commafy(data["amountReceived"], { digits: 3 });
      data["lateFee"] = this.$XEUtils.commafy(data["lateFee"], { digits: 3 });
      data["predepositOffset"] = this.$XEUtils.commafy(data["predepositOffset"], { digits: 3 });
      data["totalAmount"] = this.$XEUtils.commafy(data["totalAmount"], { digits: 2 });
      data["arrears"] = this.$XEUtils.commafy(data["arrears"], { digits: 3 });

      // 打印样式
      const printStyle = `
        * {
            font-family: "宋体";
        }
        .normal {
          font-weight: normal;
          font-size: 12px;
        }
        .title {
          font-weight: bold;
          font-size: 20px;
          text-align: center;
        }

        .my-list-row {
          display: inline-block;
          width: 100%;
        }

        .my-list-col {
          height: 28px;
          line-height: 28px;
        }

        .my-top,
        .my-bottom {
          font-size: 12px;
        }

        .my-top {
          margin-bottom: 5px;
        }

        .my-bottom {
          margin-top: 30px;
          text-align: left;
        }

        .flex {
          display: flex;
          flex: 1;
          justify-content: space-between;
        }

        .solid {
          border-bottom: 1px solid #000;
        }

        .dashed {
          border-bottom: 1px dashed #000;
        }

        .padding {
          padding: 5px;
        }

        .flex div {
          flex: 1;
          justify-content: space-between;
        }

        .col--ellipsis {
          font-size : 12px;
        }

        .flex div.time {
          flex: auto;
          display:inline-block;
          text-align:right;
        }

        .right {
          text-align:right;
        }
        `;
      // 打印顶部内容模板
      const topHtml = `
        <h1 class="title">收费票据</h1>
        <div class="solid"></div>
        <div class="my-top">
          <div class="flex">
            <div class="my-list-col" style="width:550px">流 水 号：${data.id}</div>
            <div class="my-list-col"><span>凭 证 号：${data.orderId}</span></div>
            <div class="my-list-col right"><span>收费时间：${data.payTime}</span></div>
          </div>
          <div class="flex">
            <div class="my-list-col"><span style="display:inline-block;width:550px;">资源名称：${data.communityName}/${data.floorName}/${data.unitName}/${data.roomName}[面积：${data.area}㎡]</span></div>
            <div class="my-list-col right"><span class=right>客户名称：${data.ownerName || "客户名称"}</span></div>
          </div>
        </div>
        `;
      const footerHtml = `
        <tfoot>
          <tr>
            <td class="col--ellipsis col--center" title="合计">
              <div>合计</div>
            </td>
            <td class="col--ellipsis col--center" title="" colspan="4">
              <div>${this.$Tools.convertCurrency(this.totalAmount)}</div>
            </td>
            <td class="col--ellipsis col--center" title="0.00">
              <div>${data.receivableAmount}</div>
            </td>
            <td class="col--ellipsis col--center" title="0.00">
              <div>${data.amountReceived}</div>
            </td>
            <td class="col--ellipsis col--center" title="0.00">
              <div>${data.lateFee}</div>
            </td>
            <td class="col--ellipsis col--center" title="0.00">
              <div>${data.predepositOffset}</div>
            </td>
            <td class="col--ellipsis col--center" title="0.00">
              <div>${data.totalAmount}</div>
            </td>
            <td class="col--ellipsis col--center" title="0.00">
              <div>${data.arrears}</div>
            </td>
          </tr>
          <tr>
            <td class="col--ellipsis col--center" title="摘要">
              <div>摘要</div>
            </td>
            <td class="col--ellipsis" title="" colspan="10">
              <div>${data.remark || ""}</div>
            </td>
          </tr>
        </tfoot>
      </table>
  `

      // 打印底部内容模板
      const bottomHtml = `
        <div class="my-bottom">
          <div class="dashed"></div>
          <div class="flex">
            <div class="my-list-col">操作员：${data.operator}</div>
            <div class="my-list-col">支付方式：${data.payType}</div>
            <div class="my-list-col">交款人：</div>
            <div class="my-list-col">收款人：</div>
            <div class="my-list-col">财务审核：</div>
            <span class="my-list-col time">打印时间：${data.payTime}</span>
          </div>
        </div>
        `;

      setTimeout(() => {
        this.$refs.xGrid.print({
          sheetName: "打印表格",
          style: printStyle,
          isMerge: true,
          beforePrintMethod: ({ $table, content }) => {
            // 拦截打印之前，返回自定义的 html 内容
            content = content.replace("</table>", footerHtml);
            return topHtml + content + bottomHtml;
          },
        });
      }, 300);
    },

    // 动态计算表尾的合计
    sumNum(list, field) {
      let count = 0;
      list.forEach(item => {
        count = this.$XEUtils.add(count, item[field]) // 30 Number(item[field])
      })
      return this.$XEUtils.commafy(count, { digits: 3 })
    },
  },
};
</script>

<style>
.content {
  padding: 20px;
  display: none;
}
</style>
