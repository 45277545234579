<template>
<div class="main">
  <VxeOrgTree service="/sys/sysOrganization/treeQueryAuthCommon/room" class="main_left" />
  <div class="main_right">
    <VxeBasicTable ref="xGrid" :gridOptions="gridOptions" :searchOptions="searchOptions" :defaultTrigger="false">
    </VxeBasicTable>
  </div>

  <vxe-modal ref="xModalLog" title="订单日志" width="800" height="500">
    <template v-slot>
      <div class="log">
        <el-steps direction="vertical" :active="logList.length">
          <el-step :title="`${item.orderTrajectory}【${item.creatorName}】`" :description="`${item.updateTime}${item.remark ? item.remark : ''}`" v-for="(item,index) in logList" :key="index"></el-step>
        </el-steps>
      </div>
    </template>
  </vxe-modal>

  <vxe-modal ref="refund" title="申请退费" width="600" resize remember>
    <template #default>
      <vxe-form :data="formData" ref="refundForm" :rules="rules" title-width="100" v-loading="loading" @submit="submitEvent" @reset="closeModal">
        <vxe-form-item title="退费原因" field="remark" span="24">
          <template #default>
            <vxe-textarea v-model="formData.remark" maxlength="300"></vxe-textarea>
          </template>
        </vxe-form-item>
        <vxe-form-item align="center" span="24">
          <template #default>
            <vxe-button type="submit" status="primary">提交</vxe-button>
            <vxe-button type="reset">取消</vxe-button>
          </template>
        </vxe-form-item>
      </vxe-form>
    </template>
  </vxe-modal>

  <vxe-modal ref="qrcode" :title="qrcodeTitle" width="400" height="500" resize remember>
    <template #default>
      <img :src="qrcodeImg" class="qrcode">
    </template>
  </vxe-modal>
  <PrintHtml ref="PrintHtml" />
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import VXETable from "vxe-table";
import PrintHtml from '@/components/Base/printHtml'
export default {
  components: {
    PrintHtml
  },
  computed: {
    ...mapState(["logo"]),
  },
  data() {
    return {
      currNode: {},
      loading: false,
      qrcodeTitle: "", // 2,weixin 3zhifubao 4,yinlian
      qrcodeImg: '',
      formData: {},
      rules: {
        remark: [
          { required: true, message: "请输入退费原因" },
          { min: 2, max: 30, message: "长度在 2 到 300 个字符" }
        ]
      },
      logList: [],
      selectRow: {},
      gridOptions: {
        // 列表功能按钮组
        toolbarConfig: {
          buttons: [{
            code: 'receipt',
            name: '补打收据',
            status: 'primary',
            events: {
              click: () => {
                let records = this.$refs.xGrid.$refs.xGrid.getCheckboxRecords();
                if (records.length == 1) {
                  this.getPrintDataEvent(records[0]);
                } else {
                  this.$message.error("请选择要补打的订单，仅能选择一条数据");
                }
              }
            }
          }, ],
          export: true,
          refresh: {
            query: () => {
              this.$refs.xGrid.getLists();
            },
          },
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60,
          },
          {
            type: "checkbox",
            width: 60,
          },
          {
            field: "roomName",
            title: "房屋编号",
            minWidth: 120,
            editRender: {
              name: "$select",
            }
          },
          {
            field: "orderId",
            title: "订单号",
            minWidth: 260,
            editRender: {
              name: "$VxeOrderInfo",
              props: {
                service: '/order/orderInfo',
                optionCode: 'orderStatus',
                type: 'text',
                status: "primary",
              },
            }
          },
          {
            field: "receivableAmount",
            title: "应收金额",
            minWidth: 120,
            formatter: ({ cellValue, row, column }) => {
              if (!cellValue) {
                return "0.00"
              } else {
                return this.$XEUtils.commafy(Number(cellValue), { digits: 2 })
              }
            },
          },
          {
            field: "discountDetail",
            title: "优惠金额",
            formatter: ({ cellValue }) => {
              if (!cellValue) {
                return "0.00"
              } else {
                return this.$XEUtils.commafy(Number(cellValue), { digits: 2 })
              }
            },
            minWidth: 100,
            editRender: {
              name: "$input",
              props: {
                type: "float",
                min: 0,
                max: 99999,
                digits: 2,
                maxlength: 24,
                placeholder: "请输入优惠金额"
              }
            }
          },
          {
            field: "totalAmount",
            title: "订单金额",
            minWidth: 120,
            formatter: ({ cellValue, row, column }) => {
              if (!cellValue) {
                return "0.00"
              } else {
                return this.$XEUtils.commafy(Number(cellValue), { digits: 2 })
              }
            },
          },
          {
            field: "carryWay",
            title: "进位方式",
            minWidth: 120,
            editRender: {
              name: "$select",
              optionTypeCode: "carryWay",
            }
          },
          {
            field: "serialNumber",
            title: "银行流水号",
            minWidth: 260,
          },
          {
            field: "projectType",
            title: "项目类型",
            minWidth: 160,
            visible: false,
          },
          {
            field: "payType",
            title: "支付方式",
            minWidth: 150,
            editRender: {
              name: "$select",
              optionTypeCode: "payType",
            }
          },
          {
            field: "creatorName",
            title: "操作人",
            minWidth: 120,
          },
          {
            field: "payTime",
            title: "付款时间",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                type: "datetime",
                valueFormat: "yyyy-MM-dd HH:mm:ss",
                labelFormat: "yyyy-MM-dd",
              },
            }
          },
          {
            field: "status",
            title: "订单状态",
            minWidth: 120,
            editRender: {
              name: "$select",
              optionTypeCode: "orderStatus",
            }
          },
          {
            fixed: "right",
            title: "操作",
            width: 180,
            editRender: {
              name: "$VxeGridButtons",
              lists: [{
                  name: "applyRefund",
                  status: "primary",
                  label: "申请退款",
                  params: {
                    property: "status",
                    value: ["30"],
                  },
                  events: {
                    click: (row) => {
                      this.selectRow = row
                      this.applicationRefundAllEvent(row)
                    }
                  }
                },
                {
                  name: "cancelOrder",
                  status: "primary",
                  label: "取消订单",
                  params: {
                    property: "status",
                    value: ["10"],
                  },
                  events: {
                    click: (row) => {
                      this.cancelOrderEvent(row.id)
                    }
                  }
                },
                {
                  name: "viewLog",
                  status: "primary",
                  label: "查看日志",
                  events: {
                    click: (row) => {
                      this.checkLogEvent(row.orderId)
                    }
                  }
                },
              ],
            },
          },
        ],
        editConfig: {
          enabled: false
        },
        exportConfig: {},
        importConfig: {},
        checkboxConfig: {
          checkMethod: ({ row }) => {
            return row.status != 5
          },
        }
      },
      formOptions: {
        // 新增功能字段配置
        width: 800,
        titleWidth: 110,
        items: [],
        //表单校验规则
        rules: {},
        // 新增功能字段初始化
        data: {},
      },
      // 查询功能字段配置
      searchOptions: {
        items: [{
            field: "roomName",
            title: "房间号",
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入房间号",
              },
            },
          },
          {
            field: "orderId",
            title: "订单号",
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入订单号",
              },
            },
          },
          {
            field: "serialNumber",
            title: "银行流水号",
            folding: true,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入银行流水号",
              },
            }
          },
          {
            field: "startTime",
            title: "开始时间",
            folding: true,
            itemRender: {
              name: "$input",
              props: {
                type: "date",
                valueFormat: "yyyy-MM-dd HH:mm:ss",
                placeholder: "请选择费用开始时间"
              }
            }
          },
          {
            field: "endTime",
            title: "结束时间",
            folding: true,
            itemRender: {
              name: "$input",
              props: {
                type: "date",
                valueFormat: "yyyy-MM-dd 23:59:59",
                placeholder: "请选择费用结束时间"
              }
            }
          },
          {
            field: "payType",
            title: "支付方式",
            folding: true,
            itemRender: {
              name: "$select",
              optionTypeCode: "payType",
              props: {
                type: 'text',
                status: "primary",
                placeholder: "请选择支付方式",
              },
            }
          },
          {
            field: "status",
            title: "订单状态",
            folding: true,
            itemRender: {
              name: "$select",
              optionTypeCode: "orderStatus",
              props: {
                placeholder: "请选择订单状态",
              },
            }
          },
          {
            align: "center",
            collapseNode: true,
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "查询",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "重置",
                  },
                },
              ],
            },
          },
        ],
        data: {
          roomName: "",
          payType: "",
          status: "",
          startTime: "",
          endTime: "",
          serialNumber: "",
          orderId: "",
        },
      },
    };
  },

  created() {
    // 西房特殊功能
    if (this.logo.code == "xf") {
      let { columns } = this.gridOptions;
      let item = this.$XEUtils.filterTree(columns, item => item.field === "projectType")[0];
      item.visible = true;
    }
  },

  methods: {
    ...mapActions(['submitEventService', 'findByEventService', 'removeEventService', 'postEventService', 'getEventService']),
    handleNodeClick(treeData, node) {
      this.currNode = node
      let { data } = this.searchOptions;
      data["parentId"] = node.id;
      data["parentType"] = node.type;
      this.$refs.xGrid.getLists();
    },

    // 查看日志
    checkLogEvent(orderId) {
      this.findByEventService({
        service: '/order/orderLog',
        params: {
          orderId
        },
      }).then(({ code, data, message }) => {
        if (code == 200) {
          this.logList = data
          this.$refs.xModalLog.open();
        }
      })
    },
    // 作废事件
    async cancelOrderEvent(id) {
      const type = await VXETable.modal.confirm('您确定要作废这条订单吗？')
      if (type == 'confirm') {
        this.removeEventService({
          service: `/order/order/cancel/${id}`,
          params: {},
        }).then(({ code, message }) => {
          if (code == 200) {
            this.$refs.xGrid.getLists();
            this.$message.success('作废成功')
          } else {
            this.$message.error('作废失败')
          }
        })
      }
    },
    // 申请退费
    applicationRefundAllEvent(row) {
      this.formData.remark = "";
      this.$refs.refund.open();
    },

    // 提交数据
    submitEvent() {
      this.$refs["refundForm"].validate((valid) => {
        if (!valid) {
          if (this.loading) return;
          this.loading = true
          this.postEventService({
            service: "/order/orderRefund",
            params: {
              orderId: this.selectRow.id,
              remark: this.formData.remark,
            },
          }).then(({ code, message }) => {
            if (code == 200) {
              this.$message.success('申请成功')
              this.$refs.refund.close();
              this.$refs["refundForm"].reset();
            }
            this.$refs.xGrid.getLists();
            this.loading = false
          })
        }
      });
    },

    // 关闭退费模态框
    closeModal() {
      this.$refs.refund.close();
    },
    // 打印
    getPrintDataEvent({ orderId }) {
      this.$Axios.get(`/order/order/printOrder/${orderId}`).then(({ code, data }) => {
        if (code == 200) {
          this.$refs.PrintHtml.printEvent(data);
        }
      })
    },
    // 支付
    payFor(id) {
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.postEventService({
        service: "/order/order/repayments",
        params: { id }
      }).then(({ code, data, message }) => {
        if (code == 200) {
          this.selectRow.payType
          let payType = {
            '2': '请用微信扫码',
            '3': '请用支付宝扫码',
            '4': '银联支付'
          }
          this.qrcodeTitle = payType[this.selectRow.payType]
          this.qrcodeImg = 'data:image/jpg;base64,' + data.img
          this.$refs.qrcode.open()
          let interval = setInterval(() => {
            this.getEventService({
              service: `/order/order/${id}`,
              params: {}
            }).then(({ data }) => {
              if (data.status == '30') {
                this.$refs.qrcode.close()
                this.$message.success('支付成功')
                clearInterval(interval)
              }
            })
          }, 1000)
        }
        this.loading = false;
      })
    },

  },
};
</script>

<style lang="scss" scoped>
.log {
  box-sizing: border-box;
  height: 100%;
  padding: 15px;
}

.qrcode {
  width: 400px;
  height: 400px;
  margin: 20px auto;
}
</style>
